import React,{useState} from 'react'
import Layout from "../../Components/reusable/Layout"
import Banner from "../../Components/reusable/Banner"
import img from "../../Images/products/agri.jpg"
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import store from "store"

function Productdetail() {

    const [value, setvalue] = useState(1)
    
    const [products, setproducts] = useState([])
    
     
    const inc = ()=>{
        setvalue(value+1)
    }
    const dec = ()=>{
        if (value>1) {
            setvalue(value-1)
        }
    }

    const handleChange =(e)=>{
       
            setvalue(Number(e.target.value))
        
    }


    
    const AddToEnq = () =>{

        toast.error("Product Name added for enquiry",{autoClose: 1000,})
        let newProduct = []

        const myProduct = {
            name:"product name",
            quantity:value
        }
        
        if (store.get("products")) {
            newProduct= store.get("products")
            newProduct.push(myProduct)
            store.set("products",newProduct)
            setproducts(newProduct)
        }else{
            newProduct.push(myProduct)
            store.set("products",newProduct)
            setproducts(newProduct)
        }

    }


    return (
       <Layout items={""}>
           
            <Banner title="Product Name" />
            <ToastContainer />
           <section className="my-5 py-5">
                <div className="container">
                    <div className="row">
                        <div className="col-md-6">
                            <img src={img} alt="agri" style={{width:"100%"}}/>
                        </div>
                        <div className="col-md-6">
                            <h3>Product Name</h3>
                            <p>
                                Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.
                                 Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure
                                  dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla 
                                pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.
                            </p>


                            <h6 className="mt-5">Quantity</h6>
                            <div className="mt-3 mb-5">
                                <button onClick={dec} className="btn mx-1" style={{color:"black",borderRadius:"5%", padding:"0",minWidth:"50px"}} aria-labelledby="decrement" ><i className="fas fa-minus-circle"></i></button>
                                <input  onChange={handleChange} value={value}  className="form-group" type="number" id="value"  min={"1"} style={{textAlign:"center"}}  />
                                <button onClick={inc} className="btn mx-1" style={{color:"black",borderRadius:"5%", padding:"0",minWidth:"50px"}} aria-labelledby="increment"><i className="fas fa-plus-circle"></i></button>
                            </div>
                        
                            {/* <button className="btn btn-dark" style={{display:"block"}}>Add to Enquiry</button> */}
                            

                            {/* <!-- Button trigger modal --> */}
<button type="button" style={{display:"block"}} className="btn btn-dark" data-toggle="modal" data-target="#exampleModalCenter">
Add to Enquiry
</button>

{/* <!-- Modal --> */}
<div className="modal fade" id="exampleModalCenter" tabindex="-1" role="dialog" aria-labelledby="exampleModalCenterTitle" aria-hidden="true">
  <div className="modal-dialog modal-dialog-centered" role="document">
    <div className="modal-content">
      <div className="modal-header">
        <h5 className="modal-title" id="exampleModalLongTitle">Product Name</h5>
        <button type="button" className="close" data-dismiss="modal" aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div className="modal-body">
      Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor
      </div>
      <h6>Quantity:{value}</h6>
      <div className="modal-footer">
        <button onClick={AddToEnq} type="button" className="btn btn-primary" data-dismiss="modal">Add to Enquiry</button>
      </div>
    </div>
  </div>
</div>
                        </div>
                    </div>
               
                    <div className="row my-5">
                        <div className="col-md-12">
                            <h4>Features :-</h4>
                            <ul>
                                <li>
                                    feature1
                                </li>
                                <li>
                                    feature2
                                </li>
                                <li>
                                    feature3
                                </li>
                                <li>
                                    feature4
                                </li>
                            </ul>
                        </div>
                    </div>

                    <div className="row">
                        <div className="col-md-12">
                            <h4>Descriptions :-</h4>
                            <ul>
                                <li>
                                    Descriptions1
                                </li>
                                <li>
                                    Descriptions2
                                </li>
                                <li>
                                    Descriptions3
                                </li>
                                <li>
                                    Descriptions4
                                </li>
                            </ul>
                        </div>
                    </div>
               
                </div>
           </section>
       </Layout>
    )
}

export default Productdetail
